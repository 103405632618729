@import 'contents/styles/color';
@import 'contents/styles/font';
@import 'contents/styles/mixin';

.system-maintenance {
  @include font-body();

  &__time {
    margin-bottom: 20px;

    & .between {
      padding-left: 80px;
      & span {
        display: inline-block;
        transform: rotate(90deg);
      }
    }

    & .end {
      color: $color-carmo-red;
      @include font-body-bold();
    }
  }

  &__attention {
    color: $color-carmo-red;
  }
}

.error-message {
  color: $color-carmo-red;
  font-size: 14px;
  margin-bottom: 12px;
  text-align: center;
}

.row__form.new-password {
  margin-bottom: 0;

  & .password-condition {
    margin-top: 10px;
    color: $color-carmo-red;
  }
}
