@import 'contents/styles/color';
@import 'contents/styles/font';

.attachment-statement {
    &-image {
        margin: 20px 0;

        & img {
            object-fit: contain;
        }
    }
    .carmo_red {
        color: $color-carmo-red;
    }

    .items {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        margin: 10px 0;

        .item {
            width: 165px;
            height: 51px;
            border-radius: 5px;
            border: 1px solid $color-carmo-red;

            .contents {
                display: flex;
                align-items: center;
                height: 100%;

                & img {
                    width: 23px;
                    height: 18px;
                    opacity: 0px;
                }
    
                & span {
                    margin-left: 8px;
                    font-size: 14px;
                    font-weight: 600;
                }
            }
        }
    }

    .important-point {
        margin: 20px 0;
        border: 3px solid #FFF9E6;

        &__title {
            margin-top: 20px;
            text-align: center;

            & p {
                position: relative;
                @include font-headline-medium();
                padding-bottom: 5px;
            }

            .main {
                &::after {
                    display: block;
                    content: '';
                    position: absolute;
                    bottom: 0px;
                    left: calc(50% - 95px);
                    width: 190px;
                    height: 3px;
                    background-color: $color-carmo-red;
                }
            }
        }

        &__content {
            margin: 20px 0;

            &-item {
                margin-top: 30px;

                & img {
                    width: 100%;
                }

                .comment {
                    margin: 5px 0;
                    & p {
                        text-align: center;
                    } 
                }

                .icon {
                    display: block;
                    margin: 0 auto;
                    width: 39.6px;
                    height: 39.6px;
                }

                & p {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 21px;
                }
            }
        }
    }
}
