@import 'contents/styles/color';
@import 'contents/styles/font';

$bg: #363232;

.contact {
  &__mail {
    text-align: center;
    background: $bg;
    padding-top: 20px;
    padding-bottom: 20px;

    & a {
      display: block;
      max-width: 360px;
      margin: 0 auto;
    }

    & p {
      align-items: center;
      background: #fff;
      border-radius: 5px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      color: #373232;
      display: flex;
      height: 50px;
      font-weight: 700;
      justify-content: center;
      line-height: 1;
      text-decoration: none;
      & img {
        width: 22px;
        height: auto;
      }
    }
  }

  &__tel {
    padding-bottom: 20px;
    text-align: center;
    background: $bg;
    & img {
      max-width: 360px;
    }
    & span {
      color: #fff;
      @include font-min();
    }
  }

  &__faq {
    text-align: center;
    background: $bg;
    padding-bottom: 20px;
    & a {
      color: $color-carmo-main;
      text-decoration: underline;
    }
  }
}
