@import 'contents/styles/color';
@import 'contents/styles/font';

.maint-request {
  &__column {
    margin-bottom: 20px;

    & .caution {
      margin-top: 5px;
      color: $color-carmo-red;
      @include font-annotation();
    }
  }

  &__item {
    padding: 0;
    margin: 0;
    position: relative;

    & .title {
      position: relative;
      color: #fff;
      background-color: $color-carmo-gray;
      padding: 10px 16px;
      @include font-body-bold();
      & .overview {
        position: absolute;
        top: 2px;
        right: 8px;
        @include font-body-mini-bold();
      }
    }

    & .row-item {
      & label {
        display: block;
      }
      border-bottom: 1px solid $color-carmo-light-gray;
      &__detail {
        padding: 8px 60px 16px;
        @include font-body-bold();
        & .max-amount {
          color: $color-carmo-red;
          @include font-annotation();
        }
      }
    }

    & input[type='checkbox'] {
      position: absolute;
      top: calc(50% - 12px);
      left: 20px;
      width: 24px;
      height: 24px;
      border: none;
      background: no-repeat url('/contents/images/icon/check-off.svg');
      background-size: contain;

      &:checked {
        background: no-repeat url('/contents/images/icon/check-on.svg');
        & + .row-item__detail {
          background-color: $color-carmo-main-light;
        }
      }
    }

    & img {
      position: absolute;
      top: calc(50% - 12px);
      right: 10px;
      z-index: 1;
      width: 24px;
      height: 24px;
    }

    & .btn {
      display: none;
    }
  }

  &__annotation {
    & .col {
      & p {
        color: $color-carmo-red;
        margin-bottom: 20px;
      }
    }
  }
}

.maint-item-modal {
  &__name {
    @include font-body-bold();
  }
  &__detail {
    @include font-body();
  }
  &__annotation {
    margin-top: 8px;
    @include font-annotation();
  }
}
