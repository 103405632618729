@import 'contents/styles/color';
@import 'contents/styles/font';
@import 'contents/styles/mixin';

.description {
  @include font-body();
}

.maint-request-list {
  margin: 0;

  & td {
    padding: 6px;
    text-align: center;
    vertical-align: middle;
  }

  & thead {
    & td {
      background-color: $color-carmo-middle-gray;
      border-right: 1px solid #fff;
      @include font-body-mini-bold();
      &:nth-child(1) {
        border-left: 1px solid $color-carmo-middle-gray;
      }
      &:nth-last-child(1) {
        border-right: 1px solid $color-carmo-middle-gray;
      }
    }
  }

  & tbody {
    & td {
      border: 1px solid $color-carmo-middle-gray;
      @include font-body();

      & a {
        display: inline-block;
        color: #000;
        background: #fff;
        border: 1px solid #000;
        border-radius: 50px;
        padding: 3px 24px 5px 12px;
        @include font-body-mini-bold();
        @include link-arrow-right();
      }
    }
  }
}
