@import 'contents/styles/color';
@import 'contents/styles/font';

.notice-tag {
  display: inline-block;
  padding: 0 16px;
  margin-right: 10px;
  @include font-body-bold();

  &.important {
    color: $color-carmo-red;
    border: 2px solid $color-carmo-red;
  }

  &.profit {
    color: $color-carmo-bule;
    border: 2px solid $color-carmo-bule;
  }

  &.confirm {
    color: $color-carmo-green;
    border: 2px solid $color-carmo-green;
  }
}
