.banner {
  text-align: center;
  margin-top: 20px;

  & .row {
    margin-bottom: 10px;
  }

  & img {
    width: 100%;
    height: auto;
  }
}
