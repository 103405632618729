@import 'contents/styles/color';
@import 'contents/styles/font';
@import 'contents/styles/mixin';

.maint-detail {
  &__action {
    & .col {
      text-align: center;

      & a {
        position: relative;
        display: inline-block;
        width: 80%;
        color: #000;
        background: #fff;
        border: 1px solid #000;
        border-radius: 50px;
        padding: 8px 36px 8px 16px;
        margin-top: 10px;
        margin-bottom: 20px;
        @include font-body-mini-bold();
        &::after {
          position: absolute;
          display: block;
          content: '';
          width: 24px;
          height: 18px;
          top: calc(50% - 9px);
          right: 5px;
        }
      }

      &:nth-child(1) {
        & a::after {
          background: no-repeat url('/contents/images/icon/trash.svg');
          background-size: contain;
        }
      }
      &:nth-child(2) {
        & a::after {
          background: no-repeat url('/contents/images/icon/pencil.svg');
          background-size: contain;
        }
      }
    }
  }

  &__item {
    & thead {
      & td {
        background-color: $color-carmo-middle-gray;
        border-right: 1px solid #fff;
        @include font-body-mini-bold();
        &:nth-child(1) {
          padding: 6px 10px;
        }
        &:nth-child(2) {
          padding: 6px;
          text-align: center;
          border-right: 1px solid $color-carmo-middle-gray;
        }
      }
    }
    & tbody {
      & td {
        border-bottom: 1px solid $color-carmo-middle-gray;
        padding: 6px 10px;
        &:nth-child(1) {
          border-right: 1px solid $color-carmo-middle-gray;
        }
        &:nth-child(2) {
          text-align: right;
        }
      }
    }
  }

  &__receipt {
    & label {
      display: block;
      position: relative;
      padding: 10px;
      width: 100%;
      height: 100%;
      background: $color-carmo-light-gray;
      &::after {
        position: absolute;
        content: '';
        display: block;
        right: 0;
        bottom: 0;
        width: 40px;
        height: 40px;
        background: no-repeat url('/contents/images/icon/glass.svg');
        background-size: contain;
      }
    }

    & button.btn {
      display: none;
    }
  }

  &__backward {
    text-align: center;
    & a {
      display: inline-block;
      padding: 10px 30px 10px 40px;
      border: none;
      border-radius: 50px;
      color: #fff;
      background-color: $color-carmo-red;
      @include font-headline-small();
      @include link-arrow-left(14px, 3px, #fff);
    }
  }
}
