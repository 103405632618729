@import 'contents/styles/color';
@import 'contents/styles/font';
@import 'contents/styles/mixin';

.maint-history {
  & .month {
    & p {
      &:nth-child(1) {
        @include font-body-mini-bold();
      }
      &:nth-child(2) {
        @include font-body-mini();
      }
    }
  }

  &__table {
    & table {
      & thead {
        & th {
          text-align: center;
          background-color: $color-carmo-middle-gray;
          border-right: 1px solid #fff;
          @include font-body-mini-bold();
          &:first-child {
            border-left: 1px solid $color-carmo-middle-gray;
          }
          &:last-child {
            border-right: 1px solid $color-carmo-middle-gray;
          }
        }
      }
      & tbody {
        & td {
          border: 1px solid $color-carmo-middle-gray;
          text-align: center;

          &:nth-child(1) {
            text-align: left;
          }

          & a {
            display: inline-block;
            color: #000;
            background: #fff;
            border: 1px solid #000;
            border-radius: 50px;
            padding: 3px 24px 5px 12px;
            @include font-body-mini-bold();
            @include link-arrow-right();
          }
        }
      }
    }
  }

  & .not-found {
    margin: 10px;
    text-align: center;
    @include font-body();
  }
}
