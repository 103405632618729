.accordion {
  border: none;
  & .accordion-item {
    border: none;
    border-radius: 0;

    & .accordion-header {
      border-bottom: 1px solid $color-carmo-middle-gray;
      background: #fff;
      & .accordion-button {
        color: #000;
        background-color: #fff;
        box-shadow: none;
        &::after {
          background-image: url('/contents/images/icon/square-plus.svg');
          transform: none;
        }
        &:not(.collapsed)::after {
          background-image: url('/contents/images/icon/square-minus.svg');
          transform: none;
        }
      }
    }

    & .accordion-body {
      padding: 0;
      border: none;
    }
  }
}

button {
  &.btn {
    display: inline-block;
    padding: 10px 50px;
    border: none;
    border-radius: 50px;
    @include font-headline-small();
    @include link-arrow-right(14px, 3px, #fff);

    &.btn-primary {
      background-color: $color-carmo-red;
      &:active {
        background-color: $color-carmo-red;
      }
    }
  }
}
