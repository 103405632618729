a {
  text-decoration: none;
  color: #000;
}

p {
  margin: 0;
  padding: 0;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border: none;
}

img {
  width: 100%;
}

button[type='button'] {
  &:focus {
    box-shadow: none;
  }
}

.list-group-item {
  border: none;
  padding: 0;
  text-align: left;
}

input {
  &[type='checkbox'] {
    &:focus {
      box-shadow: none;
    }
  }

  &[type='date'] {
    &:focus {
      box-shadow: none;
    }
  }
}

ul,
ol,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
