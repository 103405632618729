@import 'contents/styles/color';
@import 'contents/styles/font';

.benefit {
  background-color: $color-bg-main;
  padding-bottom: 30px;

  &__heading {
    text-align: center;
    @include font-headline-medium();

    &::after {
      content: '';
      display: block;
      width: 160px;
      height: 50px;
      margin: 0 auto 30px;
      border-bottom: 2px solid $color-carmo-main;
    }
  }
}

.benefit-header {
  &__title {
    text-align: center;
    background-image: url('/contents/images/benefit/img-bg-header.svg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 20px 0 10px;

    & h1 {
      & p {
        &:nth-child(1) {
          display: inline-block;
          padding: 0 10px;
          background: url('/contents/images/benefit/img-ribbon-header.svg');
          background-repeat: no-repeat;
          background-size: cover;
          color: #fff;
          @include font-body-bold();
        }
        &:nth-child(2) {
          margin-top: 12px;
          color: #000;
          text-shadow:
            2px 2px 0 #fff,
            -2px -2px 0 #fff,
            -2px 2px 0 #fff,
            2px -2px 0 #fff,
            0px 2px 0 #fff,
            0 -2px 0 #fff,
            -2px 0 0 #fff,
            2px 0 0 #fff;
          @include font-headline-small();
        }
      }
    }
  }

  &__main-visual {
    & .col {
      position: relative;
      padding: 0;
      border-bottom: 100px solid $color-carmo-main;
    }
  }

  &__campaign {
    position: absolute;
    top: calc(100% - 20px);
    left: calc(50% - 150px);
    margin: 0 auto;

    & img {
      width: 300px;
      height: auto;
    }
  }
}

.benefit-index {
  &__box {
    background-color: #fff;
    margin-top: 30px;
    padding: 20px;
  }

  &__title {
    text-align: center;
    @include font-headline-tiny();
  }

  &__list {
    & li {
      text-decoration-line: underline;
      line-height: 1.75;
    }
  }
}

.benefit-description {
  text-align: center;
  @include font-body();
}

.benefit-howto {
  &__step {
    position: relative;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid $color-carmo-middle-gray;
    border-radius: 0.25rem;
    background-color: #fff;

    &::before {
      display: block;
      content: '';
      position: absolute;
      top: -10px;
      left: calc(50% - 28px);
      height: 20px;
      width: 56px;
    }

    &.step1::before {
      background: url('/contents/images/benefit/img-step-1.svg') no-repeat 0 0;
    }
    &.step2::before {
      background: url('/contents/images/benefit/img-step-2.svg') no-repeat 0 0;
    }
    &.step3::before {
      background: url('/contents/images/benefit/img-step-3.svg') no-repeat 0 0;
    }

    & p {
      margin-top: 10px;
    }
  }
}

.benefit-panel__list {
  padding-bottom: 10px;
}

.benefit-campaign {
  &__title {
    padding-top: 20px;
    background-color: $color-carmo-main;
    text-align: center;
    & p {
      &:nth-child(1) {
        @include font-headline-tiny();
      }
      &:nth-child(2) {
        @include font-headline-medium();
      }
    }
  }

  &__thumbnail {
    text-align: center;
    padding-bottom: 30px;
    background-color: $color-carmo-main;

    & img {
      width: 100%;
      height: auto;
      max-width: 360px;
    }
  }
}

.benefit-magazine {
  &__thumbnail {
    text-align: center;
    & img {
      width: 80%;
      height: auto;
      max-width: 360px;
    }
  }
}

.benefit-sns {
  &__timeline {
    & .twitter-timeline {
      margin: 0 auto;
    }
  }
}
