@import 'contents/styles/color';
@import 'contents/styles/font';
@import 'contents/styles/mixin';

.home-invoice {
  box-sizing: border-box;
  background-color: #fff;
  border: 20px solid $color-carmo-main;

  &__username {
    margin: 20px 0;
    & span {
      @include font(24px, 600, 28px);
    }
  }

  &__transfer {
    margin-bottom: 10px;
    & .col,
    .col-6 {
      border: 1px solid $color-carmo-high-gray;
      margin-bottom: 5px;

      &:nth-child(1) {
        color: #fff;
        background-color: $color-carmo-high-gray;
        @include font-body-mini-bold();
      }
      &:nth-child(2) {
        text-align: right;
        @include font-headline-tiny();
      }

      & p {
        padding: 5px 0;
      }
    }
  }

  &__status {
    text-align: right;
    background-color: $color-carmo-main;
    padding-top: 5px;
    & p {
      padding-right: 25px;
      @include font-body-mini-bold();
      @include link-arrow-right();
    }
  }
}

.home-maintenance {
  padding: 20px 0;
  background-color: $color-carmo-light-gray;

  &__block {
    & a {
      display: block;
      height: 100%;
      margin: 0 5px;
      padding: 10px 5px;
      border: 3px solid $color-carmo-red;
      border-radius: 5px;
      background-color: #fff;
      text-align: center;

      & p {
        margin-bottom: 10px;
        @include font(20px, 600, 24px);
      }

      & img {
        max-width: 56px;
        height: auto;
        margin: 0 5px;
      }
    }
  }

  &__link {
    margin-bottom: 10px;
    & a {
      display: block;
      color: #000;
      background: #fff;
      border: none;
      border-radius: 50px;
      padding: 8px 36px 8px 16px;
      @include font-body-mini-bold();
      @include link-arrow-right();
    }
  }
}
