@import 'contents/styles/color';
@import 'contents/styles/font';

.image-control {
  &__left {
    background: $color-carmo-light-gray;
    & label {
      display: block;
      position: relative;
      padding: 10px;
      width: 100%;
      height: 100%;
      &::after {
        position: absolute;
        content: '';
        display: block;
        right: 0;
        bottom: 0;
        width: 40px;
        height: 40px;
        background: no-repeat url('/contents/images/icon/glass.svg');
        background-size: contain;
      }
    }

    & .btn {
      display: none;
    }
  }

  &__right {
    & .sheet-number {
      text-align: center;
    }
    & .button-frame {
      text-align: center;
      & input {
        display: none;
      }
      & p {
        position: relative;
        background-color: #fff;
        border: 1px solid #000;
        border-radius: 50px;
        padding: 5px 50px 5px 20px;
        margin: 5px 0;
        @include font-body-mini-bold();
        &::after {
          display: block;
          content: '';
          position: absolute;
          top: calc(50% - 8px);
          right: 10px;
          width: 22px;
          height: 16px;
        }
      }
    }
    & .delete-button {
      &::after {
        background: no-repeat url('/contents/images/icon/trash.svg');
        background-size: contain;
      }
    }
    & .modify-button {
      &::after {
        background: no-repeat url('/contents/images/icon/album.svg');
        background-size: contain;
      }
    }
  }

  &__add {
    width: 100%;
    background-color: $color-carmo-middle-gray;
    padding: 28px;
    text-align: center;
    & input {
      display: none;
    }
    & p {
      position: relative;
      background-color: #fff;
      border-radius: 50px;
      padding: 5px 50px 5px 20px;
      @include font-body-mini-bold();
      &::after {
        display: block;
        content: '';
        position: absolute;
        top: calc(50% - 8px);
        right: 10px;
        width: 22px;
        height: 16px;
        background: no-repeat url('/contents/images/icon/album.svg');
        background-size: contain;
      }
    }
  }

  &__message {
    color: $color-carmo-red;
    @include font-body-mini-bold();
  }
}

.image-control-modal {
  & .modal-content {
    background-color: transparent;
    border: none;

    & .modal-header {
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: 8px;
      border: none;
      & .btn-close {
        width: 36px;
        height: 36px;
        background: url('/contents/images/icon/close.svg') center/36px auto
          no-repeat;
        opacity: 1;
      }
    }
    & .modal-body {
      padding-top: 0;
      padding-bottom: 0;
      border: none;
    }
  }
}
