@import 'contents/styles/color';
@import 'contents/styles/font';

.contract-select {
  padding: 10px;

  &__header {
    background-color: $color-carmo-middle-gray;
    padding-top: 5px;
    padding-bottom: 5px;
    @include font-body-mini-bold();
  }

  &__button {
    padding-top: 10px;
    text-align: center;
  }
}
