@import 'contents/styles/color';
@import 'contents/styles/font';

.bank-info {
  & tr {
    & td {
      padding: 2px;
      &:nth-child(1) {
        padding-left: 10px;
        @include font-body-bold();
      }
      &:nth-child(2) {
        padding-right: 20px;
        text-align: right;
      }
    }
  }
}
