$color-carmo-main: #ffc300;
$color-carmo-main-light: #fef9ed;
$color-carmo-sub: #fffcf2;
$color-carmo-light-red: #ffeae8;
$color-carmo-red: #c71707;
$color-carmo-bule: #0084f8;
$color-carmo-green: #47c2b1;
$color-carmo-orange: #ff4c00;
$color-carmo-gray: #515151;
$color-carmo-high-gray: #b0b0b0;
$color-carmo-middle-gray: #dddddd;
$color-carmo-light-gray: #ececec;
$color-link: #006ebd;
$color-bg-main: #fff9e6;

$color-message-success: #0084f8;
$color-message-notice: #47c2b1;
$color-message-warning: #ff4c00;
