@import 'contents/styles/color';
@import 'contents/styles/font';
@import 'contents/styles/mixin';

.history-detail {
  &__item {
    & thead {
      & th {
        background-color: $color-carmo-middle-gray;
        border-right: 1px solid #fff;
        @include font-body-mini-bold();

        &:nth-child(1) {
          padding: 6px 10px;
        }

        &:nth-child(2) {
          padding: 6px;
          text-align: center;
          border-right: 1px solid $color-carmo-middle-gray;
        }
      }
    }
    & tbody {
      & td {
        border-bottom: 1px solid $color-carmo-middle-gray;
        padding: 6px 10px;
        &:nth-child(1) {
          border-right: 1px solid $color-carmo-middle-gray;
        }
        &:nth-child(2) {
          text-align: right;
        }
      }
    }
  }

  &__backward {
    text-align: center;
    & a {
      display: inline-block;
      padding: 10px 30px 10px 40px;
      border: none;
      border-radius: 50px;
      color: #000;
      background-color: $color-carmo-light-gray;
      @include font-headline-small();
      @include link-arrow-left(14px, 3px, #000);
    }
  }
}
