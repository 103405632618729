@import 'contents/styles/color';
@import 'contents/styles/font';

.campaign {
  &__list {
    padding-bottom: 10px;
    & .list-group-item {
      @include font();
      border-bottom: 1px dashed $color-carmo-middle-gray;
      padding: 10px 5px;

      & p {
        &:nth-child(1) {
          border: 1px solid #000;
          width: 70px;
          text-align: center;
          margin-right: 8px;
          padding: 3px;
          @include font(10px, 600, 14px);
        }
        &:nth-child(2) {
          @include font-body();
        }
      }
    }
  }
}
