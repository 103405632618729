@import 'contents/styles/color';
@import 'contents/styles/font';
@import 'contents/styles/mixin';

.header__backword {
  & a {
    & p {
      position: relative;
      color: #000;
      text-decoration: none;
      margin-left: 10px;
      padding-left: 16px;
      @include font(16px, 300);
      &::before {
        display: block;
        content: '';
        position: absolute;
        top: calc(50% - 7px);
        left: 0;
        width: 9px;
        height: 14px;
        transform: rotate(180deg);
        background: no-repeat url('/contents/images/icon/vector.svg');
      }
    }
  }
}
