@import 'contents/styles/color';

.part-loading {
  .col {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .circle {
    display: inline-block;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    background-color: $color-carmo-main;
    margin: 0 8px;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: opacity-frame 1.2s infinite ease-in-out;
    animation: opacity-frame 1.2s infinite ease-in-out;

    &:nth-child(1) {
      -webkit-animation-delay: -0.64s;
      animation-delay: -0.64s;
    }
    &:nth-child(2) {
      -webkit-animation-delay: -0.48s;
      animation-delay: -0.48s;
    }
    &:nth-child(3) {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }
    &:nth-child(4) {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }
    &:nth-child(5) {
      -webkit-animation-delay: 0s;
      animation-delay: 0s;
    }
  }
}

@-webkit-keyframes opacity-frame {
  0%,
  80%,
  100% {
    opacity: 1;
  }
  40% {
    opacity: 0.4;
  }
}

@keyframes opacity-frame {
  0%,
  80%,
  100% {
    opacity: 1;
  }
  40% {
    opacity: 0.4;
  }
}
