@import 'contents/styles/color';
@import 'contents/styles/font';

.annotation {
  position: relative;
  color: $color-carmo-red;
  border: 1px solid $color-carmo-red;
  padding: 18px 12px;
  text-align: left;
  margin-bottom: 30px;
  @include font-body();
  &::before {
    content: '';
    display: block;
    position: absolute;
    border: 10px solid transparent;
    border-top: 10px solid $color-carmo-red;
    top: 100%;
    left: calc(50% - 10px);
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: calc(100%);
    left: calc(50% - 10px + 2px);
    border: 8px solid transparent;
    border-top: 8px solid #fff;
  }
}

.row-merchant {
  margin-bottom: 20px;

  & .list-group-item {
    position: relative;
    height: 60px;
    border-bottom: 1px dashed $color-carmo-middle-gray;

    & img {
      position: absolute;
      width: 60px;
      height: auto;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    & p {
      position: absolute;
      top: 50%;
      left: 80px;
      transform: translateY(-50%);
      @include font-body-mini-bold();
    }
  }
}
