@import 'contents/styles/color';
@import 'contents/styles/font';

.benefit-panel {
  padding: 10px;
  & .panel-label {
    & span {
      display: inline-block;
      padding: 0px 4px;
      border-radius: 9999px;
      color: #fff;
      @include font(0.625rem, 600, 1.5);
      &.is-new {
        background: $color-carmo-red;
      }
      &.is-due-soon {
        background: rgb(91, 91, 91);
      }
    }
  }
  & .panel-name {
    text-align: center;
    @include font(1rem, 700, 1.2);
  }
  & .panel-summary {
    text-align: center;
    @include font(1rem, 500, 1.2);
  }
  & .card-body {
    padding: 0;

    &.panel-thumbnail {
      border: 1px solid #ebebeb;
      text-align: center;

      & img {
        width: 90%;
        border-radius: 0;
      }
    }

    & .panel-body {
      position: relative;

      & .accordion-collapse {
        &.collapse {
          height: fit-content;
          min-height: 200px;
          &:not(.show) {
            display: block;
            height: 200px;
            overflow: hidden;
          }
        }
      }
      & .accordion-body {
        padding: 20px 0 100px;
        color: #4f4f4f;
        @include font(0.875rem);
      }
      & .accordion-header {
        position: absolute;
        width: 100%;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        & .accordion-button {
          display: block;
          text-align: center;
          padding: 40px 0;
          margin: 0 auto;
          border: none;
          background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0) 5%,
            white 40%,
            white 100%
          );

          & span {
            position: relative;
            color: #5296a5;
            @include font(1rem, 400);
            &::after {
              display: block;
              content: '';
              position: absolute;
              right: -30px;
              top: 2px;
              width: 20px;
              height: 20px;
              background-image: url('/contents/images/benefit/icon-accordion-chevron.svg');
              background-repeat: no-repeat;
              background-size: 1.25rem;
            }
          }
        }
      }
    }
  }

  & .panel-link {
    display: block;
    text-align: center;
    margin: 0 auto;
    padding: 10px 0;
    border: 1px solid rgba(212, 212, 212, 1);
    border-radius: 9999px;
    box-shadow:
      0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    color: #333;
    @include font(1rem, 700, 1);
  }
}
