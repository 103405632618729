@import 'contents/styles/color';
@import 'contents/styles/font';

.result-message {
  text-align: left;
  padding: 20px 10px;
  margin: 30px 10px;
  @include font-result();

  &.success {
    color: $color-message-success;
    border: 2px solid $color-message-success;
  }

  &.notice {
    color: $color-message-notice;
    border: 2px solid $color-message-notice;
  }

  &.warning {
    color: $color-message-warning;
    border: 2px solid $color-message-warning;
  }
}
