@import 'contents/styles/color';
@import 'contents/styles/font';

.result {
  &__usedate {
    & .col {
      &:nth-child(1) {
        @include font-body-mini-bold();
      }
      &:nth-child(2) {
        text-align: right;
        @include font-body();
      }
    }

    & p {
      padding: 10px;
    }
  }

  &__item {
    & thead {
      & td {
        background-color: $color-carmo-middle-gray;
        border-right: 1px solid #fff;
        @include font-body-mini-bold();
        &:nth-child(1) {
          padding: 6px 10px;
        }
        &:nth-child(2) {
          padding: 6px;
          text-align: center;
        }
      }
    }
    & tbody {
      & td {
        border-bottom: 1px solid $color-carmo-middle-gray;
        padding: 6px 10px;
        &:nth-child(1) {
          border-right: 1px solid $color-carmo-middle-gray;
        }
        &:nth-child(2) {
          text-align: right;
        }
      }
    }
  }
}
