@import 'contents/styles/color';
@import 'contents/styles/font';
@import 'contents/styles/mixin';

.maint-confirm {
  &-column {
    margin-bottom: 30px;

    &__headline {
      margin-bottom: 10px;
      & p {
        @include font-headline-small();
        &::after {
          content: '';
          display: inline-block;
          margin-left: 10px;
          width: 16px;
          height: 16px;
          background: no-repeat url('/contents/images/icon/step-completed.svg');
          background-size: contain;
        }
      }
    }

    &__value {
      margin-bottom: 16px;
    }

    &__usedate {
      @include font-body();
    }

    &__receipt {
      & label {
        display: block;
        position: relative;
        padding: 10px;
        width: 100%;
        height: 100%;
        background: $color-carmo-light-gray;
        &::after {
          position: absolute;
          content: '';
          display: block;
          right: 0;
          bottom: 0;
          width: 40px;
          height: 40px;
          background: no-repeat url('/contents/images/icon/glass.svg');
          background-size: contain;
        }
      }

      & .btn {
        display: none;
      }
    }

    &__item {
      & .title {
        color: #fff;
        background-color: $color-carmo-gray;
        padding: 10px 16px;
        @include font-body-bold();
      }
      & .row-item {
        padding: 8px 16px;
        background-color: $color-carmo-main-light;
        border-bottom: 1px solid $color-carmo-light-gray;
        @include font-body-bold();
        & .max-amount {
          color: $color-carmo-red;
          @include font-annotation();
        }
      }
    }
  }

  &__link {
    & .col {
      text-align: center;

      & .backward {
        display: inline-block;
        width: 100%;
        padding: 10px;
        border: none;
        border-radius: 50px;
        color: #000;
        background-color: $color-carmo-light-gray;
        @include font-headline-small();
        @include link-arrow-left(14px, 3px, #000);
      }

      & .submit {
        display: inline-block;
        width: 100%;
        padding: 10px;
        border: none;
        border-radius: 50px;
        color: #fff;
        background-color: $color-carmo-red;
        @include font-headline-small();
        @include link-arrow-right(14px, 3px, #fff);
      }
    }
  }
}

.maitn-receipt-modal {
  & .modal-content {
    background-color: transparent;
    border: none;

    & .modal-header {
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: 8px;
      border: none;
      & .btn-close {
        width: 36px;
        height: 36px;
        background: url('/contents/images/icon/close.svg') center/36px auto
          no-repeat;
        opacity: 1;
      }
    }
    & .modal-body {
      padding-top: 0;
      padding-bottom: 0;
      border: none;

      & .receipt-img {
        width: 100%;
      }
    }
  }
}
