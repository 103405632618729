@mixin font($size: 16px, $weight: 300, $height: 24px, $space: 0) {
  font-family: Hiragino Kaku Gothic Pro;
  font-size: $size;
  font-weight: $weight;
  line-height: $height;
  letter-spacing: $space;
}

// タイトル
@mixin font-title() {
  @include font(32px, 600, 48px);
}
// 大見出し
@mixin font-headline-large() {
  @include font(28px, 600, 42px);
}
// 中見出し
@mixin font-headline-medium() {
  @include font(24px, 600, 36px);
}
// 小見出し.1
@mixin font-headline-small() {
  @include font(20px, 600, 24px);
}
// 小見出し.2
@mixin font-headline-tiny() {
  @include font(18px, 600, 20px);
}
// 本文.1
@mixin font-body() {
  @include font(16px, 300, 24px);
}
// 本文.1.bold
@mixin font-body-bold() {
  @include font(16px, 600, 24px);
}
// 本文.2
@mixin font-body-mini() {
  @include font(14px, 300, 20px);
}
// 本文.2.bold
@mixin font-body-mini-bold() {
  @include font(14px, 600, 20px);
}
// 注釈
@mixin font-annotation() {
  @include font(12px, 300, 14px);
}
// ミニマムテキスト
@mixin font-min() {
  @include font(10px, 600, 15px);
}
// リザルトコード
@mixin font-result() {
  @include font(14px, 700, 18px);
}

//==============================
// フォントサイズ
//==============================

// タイトル
.font-title {
  @include font-title();
}
// 大見出し
.font-headline-large {
  @include font-headline-large();
}
// 中見出し
.font-headline-medium {
  @include font-headline-medium();
}
// 小見出し.1
.font-headline-small {
  @include font-headline-small();
}
// 小見出し.2
.font-headline-tiny {
  @include font-headline-tiny();
}
// 本文.1
.font-body {
  @include font-body();
}
// 本文.1.bold
.font-body-bold {
  @include font-body-bold();
}
// 本文.2
.font-body-mini {
  @include font-body-mini();
}
// 本文.2.bold
.font-body-mini-bold {
  @include font-body-mini-bold();
}
// 注釈
.font-annotation {
  @include font-annotation();
}
// ミニマムテキスト
.font-min {
  @include font-min();
}
// リザルトコード
.font-result {
  @include font-result();
}

//==============================
// 文字色
//==============================
.font-color-carmo-main {
  color: $color-carmo-main;
}
.font-color-carmo-red {
  color: $color-carmo-red;
}
.font-color-link {
  color: $color-link;
}
