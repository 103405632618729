@import 'contents/styles/color';
@import 'contents/styles/font';

.public-site {
  &__header {
    & img {
      width: 180px;
      height: auto;
    }
  }

  &__title {
    &.container {
      padding: 0;
    }

    & h1 {
      padding: 16px 10px;
      text-align: center;
      background-color: $color-carmo-main;
      @include font-headline-medium();
    }
  }
}
