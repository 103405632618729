@import 'contents/styles/color';
@import 'contents/styles/font';

.registered-check {
  margin-bottom: 10px;
  & label {
    display: block;
    position: relative;

    & input[type='checkbox'] {
      position: absolute;
      top: calc(50% - 12px);
      width: 24px;
      height: 24px;
      border: none;
      background: no-repeat url('/contents/images/icon/check-off.svg');
      background-size: contain;

      &:checked {
        background: no-repeat url('/contents/images/icon/check-on.svg');
        & + .row-item__detail {
          background-color: $color-carmo-main-light;
        }
      }
    }

    & p {
      padding-left: 30px;
    }
  }
}

.bank-form {
  & .row {
    margin-bottom: 10px;
  }
}
