.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.7;

  &__spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center;
    width: 120px;
    height: 120px;

    animation: anm_loading_spinner 1.2s infinite linear forwards;
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
    }
    &::before {
      width: 50%;
      height: 50%;
      transform-origin: right bottom;
      border-right: 1px solid;
      background: linear-gradient(
        10deg,
        rgba(#fff, 0) 0%,
        rgba(#fff, 0.5) 72%,
        rgba(#fff, 0.6) 100%
      );
      transform: rotate(45deg);
      z-index: 1;
    }

    &::after {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      box-shadow: 0 -30px #000;
      z-index: 2;
    }
    & span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 3;
      color: #fff;
      animation: anm_loading_text 1.2s infinite linear forwards reverse;
    }
  }
}

@keyframes anm_loading_spinner {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes anm_loading_text {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg);
  }
  50% {
    opacity: 0;
    transform: translate(-50%, -50%) rotate(180deg);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
