@import 'contents/styles/color';
@import 'contents/styles/font';

.request-route {
  margin: 10px 0 20px;
  @include font-body();

  & span {
    @include font-body-bold();
  }
}
