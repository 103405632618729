@mixin font($size: 16px, $weight: 300, $height: 24px, $space: 0) {
  font-family: Hiragino Kaku Gothic Pro;
  font-size: $size;
  font-weight: $weight;
  line-height: $height;
  letter-spacing: $space;
}
// タイトル
@mixin font-title() {
  @include font(32px, 600, 48px);
}
// 大見出し
@mixin font-headline-large() {
  @include font(28px, 600, 42px);
}
// 中見出し
@mixin font-headline-medium() {
  @include font(24px, 600, 36px);
}
// 小見出し.1
@mixin font-headline-small() {
  @include font(20px, 600, 24px);
}
// 小見出し.2
@mixin font-headline-tiny() {
  @include font(18px, 600, 20px);
}
// 本文.1
@mixin font-body() {
  @include font(16px, 300, 24px);
}
// 本文.1.bold
@mixin font-body-bold() {
  @include font(16px, 600, 24px);
}
// 本文.2
@mixin font-body-mini() {
  @include font(14px, 300, 20px);
}
// 本文.2.bold
@mixin font-body-mini-bold() {
  @include font(14px, 600, 20px);
}
// 注釈
@mixin font-annotation() {
  @include font(12px, 300, 14px);
}
// ミニマムテキスト
@mixin font-min() {
  @include font(10px, 600, 15px);
}
// リザルトコード
@mixin font-result() {
  @include font(14px, 700, 18px);
}

// リンクなどにつける右向きの矢印
@mixin link-arrow-right($size: 10px, $weight: 2px, $color: #000) {
  position: relative;
  &::after {
    position: absolute;
    display: block;
    content: '';
    width: $size;
    height: $size;
    border-top: solid $weight $color;
    border-right: solid $weight $color;
    top: calc(50% - 2px);
    right: 16px;
    transform: rotate(45deg) translateY($size / 2 * -1);
  }
}

// リンクなどにつける左向きの矢印
@mixin link-arrow-left($size: 10px, $weight: 2px, $color: #000) {
  position: relative;
  &::after {
    position: absolute;
    display: block;
    content: '';
    width: $size;
    height: $size;
    border-top: solid $weight $color;
    border-right: solid $weight $color;
    top: calc(50% - 2px);
    left: 6px;
    transform: rotate(-135deg) translateY($size / 2);
  }
}
