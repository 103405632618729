@import 'contents/styles/color';
@import 'contents/styles/font';
@import 'contents/styles/mixin';

.header {
  &__logo {
    & img {
      width: 180px;
      height: auto;
    }
  }

  &__toggle {
    button {
      &.navbar-toggler {
        box-shadow: none;
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}

.global-menu {
  &__header {
    background-color: $color-carmo-main;
  }

  &__list {
    & .list-group-item {
      @include link-arrow-right();
      @include font-body();
      padding: 8px;
      border: none;
      border-bottom: 1px dashed $color-carmo-middle-gray;
    }
  }

  &__logout {
    text-align: center;
    margin-top: 30px;

    & a {
      display: inline-block;
      padding: 5px 30px;
      border: none;
      border-radius: 50px;
      @include font-headline-small();
      color: #fff;
      background-color: $color-carmo-red;
    }
  }
}
