@import 'contents/styles/color';
@import 'contents/styles/font';

dl {
  & dt {
    background-color: $color-carmo-middle-gray;
    padding: 5px 10px;
    text-align: left;
    @include font-body-bold();
  }
  & dd {
    padding: 5px 10px;
    text-align: left;
    border: none;
    @include font-body-mini();
    &.bordered {
      border: 1px solid $color-carmo-middle-gray;
    }
  }
}

.list {
  position: relative;
  margin-left: 20px;
  &::before {
    position: absolute;
    display: block;
    content: '';
    top: calc(50% - 2px);
    left: calc(-12px);
    width: 4px;
    height: 4px;
    background: #000;
    border-radius: 50%;
  }
}

.warranty-detail {
  & table {
    & tbody {
      & th,
      td {
        padding: 4px 0 2px;
        border-bottom: 1px dotted $color-carmo-middle-gray;
      }
    }
  }
}

.amount-per-month {
  text-align: center;
  padding: 20px 0;
  @include font-headline-small();
}

.amount-detail {
  @include font-body-mini();
}
