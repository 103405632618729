@import 'contents/styles/color';
@import 'contents/styles/font';
@import 'contents/styles/mixin';

.home-notice {
  padding: 10px 0 20px;
  text-align: center;

  &__title {
    & p {
      &:nth-child(1) {
        position: relative;
        @include font-headline-medium();
        padding-bottom: 5px;

        &::after {
          display: block;
          content: '';
          position: absolute;
          bottom: 0px;
          left: calc(50% - 80px);
          width: 160px;
          height: 2px;
          background-color: $color-carmo-main;
        }
      }

      &:nth-child(2) {
        @include font-annotation();
        padding-top: 10px;
      }
    }
  }

  &__list {
    padding-bottom: 10px;
    & .list-group-item {
      @include font();
      border-bottom: 1px dashed $color-carmo-middle-gray;
      padding: 10px 5px;

      & p {
        &:nth-child(1) {
          @include font-body-bold();
        }
        &:nth-child(2) {
          padding-top: 5px;
          @include font-body();
        }
      }
    }
  }

  &__link {
    & a {
      display: inline-block;
      color: #000;
      background: #fff;
      border: 1px solid #000;
      border-radius: 50px;
      padding: 8px 36px 8px 16px;
      @include font-body-mini-bold();
      @include link-arrow-right();
    }
  }

  &__none {
    & p {
      text-align: center;
      margin: 20px 0;
    }
  }
}
