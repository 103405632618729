@import 'contents/styles/color';
@import 'contents/styles/font';
@import 'contents/styles/mixin';

.warranty-document {
  text-align: center;

  & a.external-link {
    display: inline-block;
    width: 90%;
    color: #000;
    background: #fff;
    border: 1px solid #000;
    border-radius: 50px;
    padding: 8px 16px;
    text-align: center;
    @include font-body-mini-bold();
  }
}
