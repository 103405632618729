@import 'contents/styles/color';
@import 'contents/styles/font';
@import 'contents/styles/mixin';

.notice {
  padding: 10px 0 20px;
  text-align: center;

  &__list {
    padding-bottom: 10px;
    & .list-group-item {
      border-bottom: 1px dashed $color-carmo-middle-gray;
      padding: 10px 5px;

      & p {
        &:nth-child(1) {
          @include font-body-mini();
        }
        &:nth-child(2) {
          padding: 4px 0;
          @include font-body-bold();
        }
      }

      & .description {
        & p {
          min-height: 20px;
          @include font-body-mini();
        }
      }
    }
  }
}
